import { Key, useEffect, useState } from "react";
import Header from "../Header";
import { getData } from "../pages/Work";
import { imgRecord } from "../components/Types";
import Skeleton from "../components/Skeleton";

function Brandon() {
  const [imgArray, setimgArray] = useState<imgRecord[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      setimgArray(await getData(process.env.REACT_APP_AIRTABLE_BRANDON_ID));
      setIsLoading(false);
    };

    dataFetch();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="content">
          <h1>Brandon</h1>
          <p>
            Visual symbols for the Brandon values that symbolizes their
            business. The values should be able to be seen alone or together as
            a group.
          </p>
          {isLoading && <Skeleton />}
          <div className="product-grid">
            {imgArray &&
              imgArray.map(
                (imgRecord: imgRecord, index: Key | null | undefined) => {
                  console.log(imgRecord);
                  return (
                    <img
                      loading="lazy"
                      className="product"
                      src={imgRecord.fields.Attachments[0].url}
                      key={index}
                      alt={imgRecord.fields.alt}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brandon;
