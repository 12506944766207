import Header from '../Header';

function About() {
  return (
    <div className="App">
      <Header/>
      <div className="container">
        <div className='about'>
          <h1>About</h1>
          <div className='about-section'>
          <p>Graphic designer, who has as much love for creative chaos as things in order. I have a background working with product design, graphic design, pattern & print design and packaging design.
          I have also been working with brand development, trend analysis and assortment strategies. Have experience of web, print & retouching.</p>
          </div>
          <h2>Work</h2>
          <div className='about-section'>
          <h3>2016-10 - 2022-12</h3>
          <h3>Designer / Brandon AB</h3>
          <p>Head of design for the Volvo Cars account at Brandon. My main tasks was to develop all the upcoming products, from idea to finished produc. was responsible for the production in a desian stages</p>
          </div>
          <div className='about-section'>
            <h3>2015-05 - 2016-10</h3>
            <h3>Buyer for graphic production / ELLOS GROUP</h3>
            <p>Responsible for purchasing, approval and quality control of printed
            Daper. finishing and distribution. had a lot of contact with interal and external partners as well as a large budget responsibility.</p>
          </div>
          <h2>Education</h2>
          <div className='about-section'>
          <h3>2012 - 2015</h3>
          <h3>Graphic & Packaging design / Broby Grafiska</h3>
          <p>Three-vear universitv degree in graphic and packaging desian</p>
          <h3>2011 - 2012</h3>
          <h3>Design / Leksands Folkhögskola</h3>
          <p>Underaraduate studies in design</p>
          </div>
          <p>Please contact me if you would like to see my full CV!</p>
        </div>
      </div>
    </div>
  );
}

export default About;
