import { NavLink } from "react-router-dom";
import logo from '../src/logo.png'

export const Header = () => {
  return (
    <header className="header">
      <nav className="header-links">
        <NavLink
          to={`/`}
        >
          Work
        </NavLink>
        {/* <NavLink to={`/about`}>About</NavLink> */}
        <NavLink to={`/contact`}>Contact</NavLink>
      </nav>
      <div className="logo">
        <img
          alt="logo"
          src={logo}
        />
      </div>
    </header>
  );
};

export default Header;
