function Skeleton() {
  return (
    <div className="loading">
      <div className="skeleton-box" />
      <div className="skeleton-box" />
      <div className="skeleton-box" />
    </div>
  );
}

export default Skeleton;
